.html {
  width: 0;
  height: 0;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
}
