.page {
  width: 100%;
  padding: 4rem;
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 1280px) {
  .page {
    flex-direction: column;
  }
}
