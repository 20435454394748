.sizingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 80%;
    max-height: 80%;
  }
}

.canvasContainer {
  position: relative;
  width: calc(100% - 80px);
  left: 80px;
  height: 100%;
}
