.inputContainer {
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.swicthBody {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: lightgrey;
  position: relative;
  cursor: pointer;
  .switchHead {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: gray;
    border-radius: 15px;
    transition: all 0.2s;
  }
  .switchHead:hover {
    box-shadow: 0px 0px 2px 2px whitesmoke;
  }
}

.swicthSelected {
  background-color: green;
  .switchHead {
    transform: translateX(20px);
    background-color: lightgreen;
  }
}

.disabled {
  position: relative;
}

.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 2;
  cursor: not-allowed;
}

.info-holder {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}
.info {
  position: fixed;
  background: white;
  z-index: 9999;
  font-size: 0.9rem;
  width: 400px;
  transform: translate(calc(-55%), 30%);
  padding: 20px;
  border-radius: 10px;
  color: black;
  box-shadow: 0px 0px 5px gray;
}

.info-hidden {
  display: none;
}
