.overlay-input {
  transform: translate(-50%, -50%);
  user-select: none;
  font-size: 15px;
}

.overlay-input-disabled {
  pointer-events: none;
}

.overlay-input-field {
  width: 3.5ch;
  text-align: right;
  font-size: 15px;
  background: transparent;
  border: none;
  padding: 0px 2px;
}

.overlay-input-field:hover,
.overlay-input-field:focus {
  background: white;
}
