.form {
  min-width: 457px;
  width: 457px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.form-sections {
  height: calc(100% - 80px);
  overflow: auto;
}

.checkout-section {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 80px;
}

.disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  z-index: 100;
  p {
    font-size: 18px;
    text-align: center;
  }
  button {
    padding: 10px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
}

.disabledBackground {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.9;
  z-index: -1;
}

@media only screen and (max-width: "1024px") {
  .form {
    width: 100%;
  }

  .form-sections {
    display: flex;
  }
}
