.form {
  width: 60%;
}

.title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
}

.customerType {
  padding-top: 1.75rem;
  display: flex;
  gap: 1.25rem;
  font-size: 14px;
  .customerTypeField {
    display: flex;
    gap: 0.5rem;
  }
}

.customerInfoData {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2.5rem;
  row-gap: 0.5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  .infoInputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    .addressData {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 0.75rem;
      row-gap: 0.5rem;
    }
  }
}

.inputField {
  height: 58px;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--background);
  padding: 0.5rem;
  font-size: 1rem;
}

.radiosContainer {
  padding-top: 1.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  border-radius: 1rem;
  width: 100%;
  background-color: var(--background);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.radioContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.cartItems {
  width: 40%;
  .items {
    max-height: 40vh;
    overflow-y: scroll;
    padding: 0 1rem;
  }
}

textarea {
  resize: vertical;
}

.cartPrice {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  margin: 0.25rem 0;
}

.cartSummary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.checkoutItem {
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 2rem;
  padding: 1rem 0;
  .itemImage {
    width: 30%;
    padding: 0 1.25rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .itemData {
    width: 70%;
    .itemTitle {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
    .itemDataRow {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .form {
    width: 100%;
  }
  .cartItems {
    width: 100%;
  }

  .customerInfoData {
    grid-template-columns: 100%;
  }
  .addressData {
    grid-template-columns: 100% !important;
  }
}
