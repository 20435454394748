.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  h1 {
    font-size: 100px;
  }
  p {
    font-size: 30px;
  }
  button {
    padding: 15px 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
