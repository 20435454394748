.menuContainer {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.save,
.checkout,
.menuOption {
  background-color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 10px;
  border: 1px solid black;
}
