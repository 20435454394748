.undo {
  position: absolute;
  left: 50%;
  bottom: 20px;
  padding: 7px 20px;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 100;
  cursor: pointer;
  img {
    padding-right: 10px;
  }
}
