.placeholder {
  color: var(--dark--gray);
  text-align: center;
}

.item {
  position: relative;
  .info {
    position: absolute;
    top: 110%;
    left: 0;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    z-index: 99;
    width: 120%;
    transform: translateX(-10%);
    color: black;
    display: none;
    font-size: 0.9rem;
    border: 1px solid var(--gray);
  }
}

.limits {
  margin: 12px 0;
}

.limitation {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}

.limitation::first-letter {
  text-transform: capitalize;
}

.item:hover {
  .info {
    display: block;
  }
}
