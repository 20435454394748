.container {
  min-width: 457px;
  width: 457px;
  height: 100%;
  position: relative;
  overflow: hidden;
  h3 {
    font-size: 2em;
    padding: 10px;
  }
}
.sideDescription {
  h4 {
    width: 100%;
    background-color: var(--green);
    color: white;
    padding: 10px 20px;
    text-transform: capitalize;
  }
  .connection {
    padding: 10px 30px;
  }
}

.sides {
  overflow: auto;
  height: calc(100% - 150px);
  padding-bottom: 20px;
}

.buttons {
  position: absolute;
  bottom: 0;
  display: flex;
  .button {
    color: white;
    background-color: var(--green);
    border: none;
    padding: 20px;
    font-size: 20px;
    text-transform: uppercase;
  }
}
