.menuContainer {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: white;
  border-radius: 0 10px 10px 0;
  width: 80px;
  overflow: hidden;
  padding: 10px;
}

.menuOption {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s;
  img {
    width: 90%;
  }
  p {
    font-size: 0.8em;
    text-align: center;
  }
}

.menuOptionSelected {
  border-color: var(--primary);
  box-shadow: 0 0 5px var(--primary);
}
