.controls {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  .controlButtons {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    gap: 5px;
    .controlButton {
      padding: 10px;
      text-transform: uppercase;
      background-color: var(--green);
      border: none;
      color: white;
    }
  }
}
