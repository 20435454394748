.formContainer {
  width: 100%;
  height: auto;
  padding: 1rem 2rem;
  textarea {
    resize: none;
  }
}

.input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0;
  border: 1px solid black;
  width: 100%;
}

.input:focus {
  outline: none;
  border-color: var(--green);
}

.inputError {
  border-color: red;
}

.formInputs {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: min-content;
  height: 80%;
}

.inputContainer {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  input,
  textarea {
    background-color: var(--secondary);
    border: none;
    border-radius: 10px;
    padding: 15px;
  }
}

.submitContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  padding: 10px 15px;
  color: white;
  background-color: var(--primary);
  border-radius: 0;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  text-transform: uppercase;
}

.submitButton:hover {
  background-color: var(--primary-dark);
}

.description {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.saved {
  text-align: center;
  h2 {
    font-size: 32px;
    margin-bottom: 1.5rem;
  }
  a {
    color: black;
  }
}

@media screen and (max-width: 768px) {
  .formInputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
