.inputSelectImage {
  width: calc(50% - 20px);
  margin: 10px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
