.formContainer {
  width: 100%;
  height: 400px;
  padding: 1rem 2rem;
}

.input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0;
  border: 1px solid black;
  width: 100%;
}

.input:focus {
  outline: none;
  border-color: var(--green);
}

.inputError {
  border-color: red;
}

.formInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  height: 80%;
}

.inputContainer {
  display: flex;
  padding: 10px;
  width: 100%;
}

.submitContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  padding: 15px;
  color: white;
  background-color: var(--green);
  border-radius: 0;
  border: none;
  font-size: 1rem;
}

.submitButton:hover {
  background-color: var(--dark-green);
}

@media screen and (max-width: 768px) {
  .formInputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
