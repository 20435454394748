.input-slider {
  position: relative;
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .title {
    width: 15%;
    font-size: 0.9rem;
  }
  .control-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    border-radius: 99px;
    width: 30px;
    height: 30px;
    border: none;
    background: var(--primary);
    color: white;
    padding: 5px;
    cursor: pointer;
  }

  .control-button:hover {
    background-color: var(--primary-dark);
  }

  .input-field {
    width: 5ch;
    text-align: right;
    left: 0;
    font-size: 1rem;
    background-color: white;
    border: none;
    padding: 6px 3px;
  }
  .input-field:focus {
    outline: none;
    border-radius: 3px;
  }

  .range {
    -webkit-appearance: none;
    appearance: none;
    background-color: lightgray;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }

  .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background: var(--primary);
  }

  .range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background: var(--primary);
    cursor: pointer;
  }

  .range:active {
    cursor: grabbing;
  }

  .range:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.info-holder {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}
.info {
  position: fixed;
  background: white;
  z-index: 9999;
  font-size: 0.9rem;
  width: 220px;
  transform: translate(calc(-50% - 15px), 50%);
  padding: 20px;
  border-radius: 10px;
  color: black;
  box-shadow: 0px 0px 5px gray;
}

.info-hidden {
  display: none;
}
