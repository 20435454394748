.configurator {
  display: flex;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: "1024px") {
  .configurator {
    flex-direction: column;
  }
}
