.overlayBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .circle {
    width: 10px;
    height: 10px;
    background-color: var(--dark-gray);
    opacity: 0.5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: white;
      display: none;
      font-size: 2em;
      user-select: none;
    }
  }
}

.overlayBorderSelected {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23FFD24EFF' stroke-width='5' stroke-dasharray='30%2c30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  .circle {
    width: 25px;
    height: 25px;
    opacity: 1;
    .text {
      display: inline;
    }
  }
}

.overlayBorder:hover {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23FFD24EFF' stroke-width='5' stroke-dasharray='30%2c30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  .circle {
    width: 25px;
    height: 25px;
    opacity: 1;
    .text {
      display: inline;
    }
  }
}
