.header {
  background: white;
  width: 100%;
  padding-top: 1.8rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.headerContent {
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
