.indicator {
  width: 20px;
  height: 20px;
  z-index: 22;
  border-radius: 50%;
  background: white;
  user-select: none;
  color: gray;
  text-align: center;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    pointer-events: none;
  }
}

.indicator-up-down {
  cursor: ns-resize;
}

.indicator-left-right {
  cursor: ew-resize;
}

.indicator-back {
  border: 2px solid green;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.disabled {
  pointer-events: none;
  width: 10px;
  height: 10px;
  img {
    pointer-events: none;
  }
}

.disabled-html {
  pointer-events: none;
}

.clicked {
  pointer-events: none;
  background: green;
  z-index: 99;
  img {
    pointer-events: none;
  }
}

.html {
  width: 0;
  height: 0;
}
