.select-image {
  width: calc(50% - 20px);
  border: 2px solid transparent;
  background-color: white;
  box-shadow: 0px 0px 4px gray;
  margin: 10px;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  color: black;
  align-items: center;
  height: 60px;
  cursor: pointer;
  transition: all 0.1s;
  .title {
    text-align: left;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 5px;
  }
  img {
    max-width: 40%;
    height: 100%;
  }
}

.select-image-checked {
  border-color: var(--primary);
  box-shadow: 0px 0px 4px var(--primary);
}

.select-image-disabled {
  opacity: 0.5;
}
