:root {
  --primary: #8cd154;
  --primary-dark: #6cb333;

  --secondary: #f4e9d7;

  --background: #f4f5f9;

  --yellow: #ffd24e;
  --green: #2bc4a7;
  --dark-green: #17a387;
  --gray: #8b8b8b;
  --dark-gray: #555555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

.App {
}

.page {
  height: calc(100vh - 120px);
}

a:focus,
input:focus,
button:focus {
  outline: none;
}
