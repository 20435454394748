.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2147483647;
}

.modal {
  width: 40%;
  min-height: 40%;
  min-width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px black;
  font-weight: 100;
  padding: 1rem 2rem;
}

.s {
  width: 40%;
  min-height: 40%;
}

.m {
  width: 60%;
  min-height: 50%;
}

.l {
  width: 80%;
  min-height: 70%;
}

.xl {
  width: 100vw;
  min-height: 100vh;
}

.modalTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;
}

.modalTitle {
  font-weight: 400;
  font-size: 1.5rem;
  font-weight: bold;
}

.childrenContainer {
  height: 90%;
  padding: 20px;
}

.closeButton {
  border: 0;
  background-color: transparent;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
  cursor: pointer;
}
