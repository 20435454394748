.form {
  transform: translateX(-50%);
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  background: white;
}

.form::before {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  background-color: white;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.form::after {
  content: "";
  width: 110%;
  height: 170%;
  position: absolute;
  left: -5%;
  bottom: -30%;
  z-index: -1;
}

.input-field {
  width: 5ch;
  padding: 2px 5px 2px 0px;
  text-align: right;
  border: none;
}

.input {
  box-shadow: 0px 0px 2px 2px gray;
  height: 24px;
  padding: 0;
  font-size: 12px;
  line-height: 26px;
  margin: 0 10px;
}

.buttons {
  button {
    margin-left: 3px;
    height: 12px;
    width: 12px;
    font-size: 10px;
    border: none;
    display: block;
    text-align: center;
    border: 1px solid gray;
    background: white;
  }
}
