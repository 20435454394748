.form-section {
  width: 100%;
  position: relative;
}

.form-section-header {
  background: var(--primary-dark);
  color: white;
  width: 100%;
  padding: 0.9rem 1.9rem;
  text-align: left;
  font-weight: 300 !important;
  font-size: 1.3rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.2s;
}

.form-section-title {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600;
  margin-right: 5px;
}

.form-section-content {
  box-shadow: 2px 2px 5px 0;
  padding: 1.9em 2em 0.5em;
  background: var(--background);
  color: var(--dark-gray);
  width: 100%;
}

.form-section-description {
  font-size: 0.9rem;
  color: var(--dark-gray);
  text-align: center;
  display: block;
  margin-bottom: 0.9rem;
  a {
    text-decoration: none;
    color: var(--primary);
  }
  a:hover {
    color: var(--primary-dark);
    text-decoration: underline;
  }
}

.next-button {
  background-color: var(--primary);
  display: block;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border: none;
  color: white;
  border-radius: 15px;
  font-weight: bold;
}

.next-button:hover {
  background-color: var(--primary-dark);
}
.info-holder {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}
.info {
  position: fixed;
  background: white;
  z-index: 9999;
  font-size: 0.7em;
  width: 400px;
  transform: translate(calc(-55%), 30%);
  padding: 20px;
  border-radius: 10px;
  color: black;
  box-shadow: 0px 0px 5px gray;
}

.info-hidden {
  display: none;
}

.collapsed {
  .form-section-header {
    background: white;
    color: gray;
    padding: 0.5rem 1.9rem;
  }
  .title {
    font-weight: 100;
  }
  .form-section-content {
    display: none;
  }
}

.back-button {
  display: none;
}

.collapseButton {
  display: block;
}

@media only screen and (max-width: "1024px") {
  .collapsed {
    display: none;
  }
  .back-button {
    display: block;
  }
  .collapseButton {
    display: none;
  }
}
