.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  height: 100%;
}

.checkoutButton {
  border: none;
  font-size: 0.9rem;
  font-weight: bold;
  height: 100%;
  background-color: var(--primary);
  color: white;
  width: 40%;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  span {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.checkoutButton:hover {
  background-color: var(--primary-dark);
}

.price {
  width: 60%;
  height: 100%;
  min-width: 10ch;
  font-size: 2rem;
  padding: 1rem;
  text-transform: uppercase;
  background-color: var(--secondary);
  color: var(--dark-gray);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.button {
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
}
.saveButton {
  background-color: var(--gray);
  color: white;
}

.saveButton:hover {
  background-color: var(--dark-gray);
}
